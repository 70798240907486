import React, { Component } from "react";
import PropTypes from "prop-types";
import { SignedIn, SignedOut } from "../UserState/UserState";
import EpisodeServers from "../Servers/EpisodeServers";
import "./EpisodeMobileItem.scss";
import Seo from "../Seo";
import ShareButton from "../PrimaryButton/ShareButton";

class EpisodeMobileItem extends Component {
  static propTypes = {
    addEpisode: PropTypes.func.isRequired,
    removeEpisode: PropTypes.func.isRequired,
    watched: PropTypes.bool.isRequired,
    showId: PropTypes.string.isRequired,
    episodeNumber: PropTypes.number.isRequired,
    seasonNumber: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  };

  state = {
    isOpen: false,
    modalIsOpen: false,
  };

  showModal = () => {
    this.setState({ modalIsOpen: true });
  };

  hideModal = () => {
    this.setState({ modalIsOpen: false });
  };

  toggle = () => {
    this.setState((state) => ({ isOpen: !state.isOpen }));
  };

  render() {
    const { toggle, hideModal, showModal } = this;
    const { isOpen } = this.props;
    const { modalIsOpen } = this.state;
    const {
      addEpisode,
      removeEpisode,
      watched,
      name,
      title,
      episodeNumber,
      seasonNumber,
      description,
      poster,
      showId,
    } = this.props;

    let mobileItem;
    let checkBox;
    const show = {
      id: this.props.showId,
      episodeNumber: this.props.episodeNumber,
      seasonNumber,
    };

    if (watched) {
      checkBox = (
        <button
          className="episodeMobileCheckbox"
          onClick={() => removeEpisode(show)}
        ></button>
      );
    } else {
      checkBox = (
        <button
          className="episodeMobileCheckbox"
          onClick={() => addEpisode(show)}
        ></button>
      );
    }

    if (this.state.isOpen) {
      mobileItem = (
        <div className="expandedItemBox">
          <div className="expandedTitleBar">
            <div className="episodeNumber">{episodeNumber}</div>
            <div className="episodeTitleBox" onClick={toggle}>
              {name}
            </div>
          </div>
          <div className="expandedDescriptionBox">
            {description} <br />
            <ShareButton
              onClick={showModal}
              title={`Play Episode ${episodeNumber}`}
              style={{ display: name ? "block" : "none", "margin-top": "10px" }}
            />
          </div>
          <Seo
            title={`Watch ${name} Episode ${episodeNumber} - Season ${seasonNumber} Free`}
            descriptions={`Watch ${name} Episode ${episodeNumber}, Season ${seasonNumber} in HD Quality for free on FlixWatch. Enjoy fast and ad-free streaming with subtitles in multiple languages. Download ${name} Episode ${episodeNumber} in 1080p, 720p, and 480p. No sign-up required!`}
            keywords={`Watch ${name} Episode ${episodeNumber}, Stream ${name} Episode ${episodeNumber} free, Watch ${name} online free, Download ${name} Episode ${episodeNumber} in HD, Watch ${name} Season ${seasonNumber} full episodes, ${name} Episode ${episodeNumber} free streaming, Best site to watch ${name}, Where to watch ${name} Episode ${episodeNumber}, Watch ${name} in multiple languages, ${name} Episode ${episodeNumber} with subtitles, Watch ${name} Episode ${episodeNumber} without ads, ${name} Episode ${episodeNumber} 1080p download, ${name} Episode ${episodeNumber} 720p stream, Download ${name} Season ${seasonNumber} all episodes, Watch ${name} Episode ${episodeNumber} Google Drive, ${name} Episode ${episodeNumber} torrent download, Watch ${name} latest episode online, Best TV show streaming site, Free streaming for ${name} Episode ${episodeNumber}, ${name} Episode ${episodeNumber} Blu-ray download, Legally watch ${name} online`}
          />

          <EpisodeServers
            isOpen={modalIsOpen}
            hideFunc={hideModal}
            showId={showId}
            url={poster}
            episodeNumber={episodeNumber}
            seasonNumber={seasonNumber}
            name={name}
            title={title}
          />
        </div>
      );
    } else {
      mobileItem = (
        <div className="episodeMobileItem">
          <div>
            <div className="episodeNumber">{episodeNumber}</div>
          </div>
          <div className="episodeMobileTitle">{name}</div>
          <button className="expandBoxButton" onClick={toggle} />
          <SignedIn>{() => checkBox}</SignedIn>
          {/* put an empty div so the three column layout still works */}
          <SignedOut>
            <div />
          </SignedOut>
        </div>
      );
    }

    return <>{mobileItem}</>;
  }
}

export default EpisodeMobileItem;
