import React from "react";
import Helmet from "react-helmet";

const Seo = ({ title, pathSlug, crawl, coverImg, keywords, descriptions }) => {
  const baseKeywords = [
    "watch free movies online", "stream HD movies", "watch TV shows online", "best movie site",
    "download movies in 1080p", "multilingual movies streaming", "anime streaming online",
    "watch Hollywood movies free", "Bollywood movies download", "latest Netflix series",
    "watch anime with subtitles", "best streaming platform 2024", "stream action movies",
    "top-rated movies online", "free movie download", "no ads movie streaming",
    "watch trending movies", "dubbed anime streaming", "FlixWatch free movies",
    "watch movies without signup", "unlimited streaming", "free streaming no ads",
    "fast movie streaming site", "best anime streaming site", "watch TV series in HD",
    "high-quality movie downloads", "instant movie streaming", "popular movies 2024",
    "classic movies online", "horror movies free streaming", "sci-fi movies online",
    "best movie streaming websites", "where to watch movies free", "watch Korean dramas free",
    "live-action movies streaming", "watch Disney movies free", "Marvel movies online free",
    "DC movies free streaming", "watch full episodes online", "binge-watch TV shows free",
    "watch international movies online", "top anime streaming sites"
  ];

  const updatedKeywords = keywords
    ? `${keywords}, ${baseKeywords.join(", ")}`
    : baseKeywords.join(", ");

  const updatedDescriptions = descriptions
    ? `${descriptions} Stream the best movies, TV shows, and anime in HD for free on FlixWatch. Watch in multiple languages with subtitles.`
    : "FlixWatch.site lets you stream the latest movies, TV shows, and anime in multiple languages. Watch in 1080p, 720p, and 480p quality without ads.";

  const isIndex = crawl === "no" ? "noindex, nofollow" : "index, follow";
  const isCoverImg = coverImg ? coverImg : "/cover.png";
  const currentURL = window.location.href;

  return (
    <Helmet htmlAttributes={{ lang: "en" }}>
      <title>{title} | FlixWatch</title>
      <meta name="robots" content={isIndex} />
      <link rel="canonical" href={currentURL} />
      <meta name="description" content={updatedDescriptions} />
      <meta name="keywords" content={updatedKeywords} />

      {/* Open Graph (Facebook, LinkedIn, WhatsApp) */}
      <meta property="og:type" content="website" />
      <meta property="og:url" content={currentURL} />
      <meta property="og:title" content={`${title} | FlixWatch`} />
      <meta property="og:description" content={updatedDescriptions} />
      <meta property="og:image" content={isCoverImg} />

      {/* Twitter Card */}
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:url" content={currentURL} />
      <meta property="twitter:title" content={`${title} | FlixWatch`} />
      <meta property="twitter:description" content={updatedDescriptions} />
      <meta property="twitter:image" content={isCoverImg} />
    </Helmet>
  );
};

export default Seo;
