import React, { Component } from "react";
import Download from "../Torrents/DownloadItem.jsx";
import { Link } from "react-router-dom";

class EpisodeServers extends Component {
  state = {
    selectedOption: "",
    openNotif: false,
    iframeLoaded: false,
    display: true,
    links: false,
  };

  handleChange = (selectedOption) => {
    this.setState({
      selectedOption: selectedOption.value,
      openNotif: true,
      serverStatus: false,
    });
  };

  toggleLinks = () => {
    this.setState((prevState) => ({ links: !prevState.links }));
  };

  cancelModal = () => {
    this.props.hideFunc();
    this.setState({ iframeLoaded: false });
  };

  openIframe = () => {
    this.setState({ iframeLoaded: true, openNotif: false });
  };

  renderPlayer = () => {
    const { showId, imdb_id, episodeNumber, seasonNumber, url } = this.props;
    const server = localStorage.getItem("server_series");
    const serverLinks = {
      1: `//vidlink.pro/tv/${showId}/${seasonNumber}/${episodeNumber}?primaryColor=63b8bc&secondaryColor=a2a2a2&iconColor=eefdec&icons=default&player=default&title=true&poster=true&autoplay=true&nextbutton=true`,
      2: `//vidjoy.pro/embed/tv/${imdb_id}/${seasonNumber}/${episodeNumber}`,
      3: `//embed.su/embed/tv/${imdb_id}/${seasonNumber}/${episodeNumber}`,
      4: `//player.autoembed.cc/embed/tv/${imdb_id}/${seasonNumber}/${episodeNumber}?server=2`,
      5: `//vidbinge.dev/embed/tv/${imdb_id}/${seasonNumber}/${episodeNumber}`,
      6: `//111movies.com/tv/${imdb_id}/${seasonNumber}/${episodeNumber}`,
      7: `//player.smashy.stream/tv/${imdb_id}?s=${seasonNumber}&e=${episodeNumber}`,
      8: `//embed.warezcdn.link/serie/${imdb_id}/${seasonNumber}/${episodeNumber}`,
      9: `//frembed.live/api/serie.php?id=${imdb_id}&sa=${seasonNumber}&epi=${episodeNumber}`,
      10: `//player.autoembed.cc/embed/tv/${imdb_id}/${seasonNumber}/${episodeNumber}?server=7`,
      11: `//vidsrc.xyz/embed/tv/${imdb_id}/${seasonNumber}/${episodeNumber}`,
    };
    const link = serverLinks[server] || serverLinks[1];

    return (
      <Download
        handleChange={this.cancelModal}
        url={url}
        isOpen={this.props.isOpen}
        link={link}
      />
    );
  };

  render() {
    const { isOpen } = this.props;
    const isVideoOpen = isOpen ? "is-modal-active" : "";

    return (
      <>
        <div className={`modal modal--fullscreen ${isVideoOpen}`}>
          <div className="modal__dialog">
            <div className="modal__content">
              <div className="change_link_icon" onClick={this.toggleLinks}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
                </svg>
              </div>

              {this.state.links && (
                <div id="open-modal" className="modal-window">
                  <div>
                    <a
                      onClick={this.toggleLinks}
                      title="Close"
                      className="modal-close"
                    >
                      Close
                    </a>
                    <h1>Not Working!</h1>
                    <Link to="/settings">
                      <button className="gdrive-btn">Change Server</button>
                    </Link>
                    <div>
                      <small>Vidsrc, Hindi Server, Vidsrc VIP</small>
                    </div>
                    <a href="" target="_blank">
                      👉 Recommended Alternative!
                    </a>
                  </div>
                </div>
              )}

              {this.renderPlayer()}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EpisodeServers;
