import React, { Component } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Download from "../Torrents/DownloadItem.jsx";
import { updateLink } from "../../Firebase/lists";

class MovieServers extends Component {
  state = {
    display: true,
    links: false,
    movie_link: "",
    addedmsg: false,
    errormsg: false,
    populardownload: "",
    getData: "",
  };

  componentDidMount() {
    const pop_status = localStorage.getItem("server_movie");
    if (pop_status == 2) {
      axios
        .get(
          `https://yts.mx/api/v2/list_movies.json?query_term=${this.props.imdb}&with_images=true&with_cast=true`
        )
        .then((response) => {
          const movie = response.data.data.movies[0];
          const torrents = movie.torrents.find(
            (torrent) => torrent.quality === "1080p"
          );
          if (torrents) {
            this.setState({ getData: torrents.hash });
          }
        })
        .catch((err) => console.log(err));
    }
  }

  cancelModal = () => {
    this.props.hideFunc();
    this.setState({ display: true });
  };

  openLinks = () => this.setState({ links: true });

  closeLinks = () => this.setState({ links: false });

  submitLink = (event) => {
    event.preventDefault();
    if (this.state.movie_link) {
      updateLink(this.props.id, this.state.movie_link);
      this.setState({ addedmsg: true });
      setTimeout(() => window.location.reload(false), 3000);
    } else {
      this.setState({ errormsg: true });
    }
  };

  handleChange = (event) => {
    this.setState({ movie_link: event.target.value });
  };

  getServerLink = (server, imdb) => {
    const serverLinks = {
      1: `//vidlink.pro/movie/${imdb}`,
      2: `//vidjoy.pro/embed/movie/${imdb}`,
      3: `//embed.su/embed/movie/${imdb}`,
      4: `//player.autoembed.cc/embed/movie/${imdb}?server=2`,
      5: `//vidbinge.dev/embed/movie/${imdb}`,
      6: `//111movies.com/movie/${imdb}`,
      7: `//player.smashy.stream/movie/${imdb}`,
      8: `//embed.warezcdn.link/filme/${imdb}`,
      9: `//frembed.live/api/film.php?id=${imdb}`,
      10: `//player.autoembed.cc/embed/movie/${imdb}?server=7`,
      11: `//vidsrc.xyz/embed/movie/${imdb}`,
    };

    return serverLinks[server] || `//vidlink.pro/movie/${imdb}`;
  };

  render() {
    const { isOpen, imdb, url } = this.props;
    const { cancelModal, openLinks } = this;
    const isVideoOpen = isOpen ? "is-modal-active" : "";
    const server = localStorage.getItem("server_movie");
    const link = this.getServerLink(server, imdb);

    return (
      <>
        <div className={`modal modal--fullscreen ${isVideoOpen}`}>
          <div className="modal__dialog">
            <div className="modal__content">
              <div className="change_link_icon" onClick={openLinks}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#fff"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                >
                  <path d="M6.188 8.719c.439-.439.926-.801 1.444-1.087 2.887-1.591 6.589-.745 8.445 2.069l-2.246 2.245c-.644-1.469-2.243-2.305-3.834-1.949-.599.134-1.168.433-1.633.898l-4.304 4.306c-1.307 1.307-1.307 3.433 0 4.74 1.307 1.307 3.433 1.307 4.74 0l1.327-1.327c1.207.479 2.501.67 3.779.575l-2.929 2.929c-2.511 2.511-6.582 2.511-9.093 0s-2.511-6.582 0-9.093l4.304-4.306zm6.836-6.836l-2.929 2.929c1.277-.096 2.572.096 3.779.574l1.326-1.326c1.307-1.307 3.433-1.307 4.74 0 1.307 1.307 1.307 3.433 0 4.74l-4.305 4.305c-1.311 1.311-3.44 1.3-4.74 0-.303-.303-.564-.68-.727-1.051l-2.246 2.245c.236.358.481.667.796.982.812.812 1.846 1.417 3.036 1.704 1.542.371 3.194.166 4.613-.617.518-.286 1.005-.648 1.444-1.087l4.304-4.305c2.512-2.511 2.512-6.582.001-9.093-2.511-2.51-6.581-2.51-9.092 0z" />
                </svg>
              </div>

              <div
                id="open-modal"
                className="modal-window"
                style={{ display: this.state.links ? "block" : "none" }}
              >
                <div>
                  <a
                    onClick={this.closeLinks}
                    title="Close"
                    className="modal-close"
                  >
                    Close
                  </a>
                  <h1>Not Working!</h1>
                  <Link to={`/settings`}>
                    <button className="gdrive-btn">Change Server</button>
                  </Link>
                  <small>Vidsrc, Hindi Server, Vidsrc VIP</small>
                  <a href="" target="_blank">
                    👉 Recommended Alternative!
                  </a>
                </div>
              </div>

              <Download
                handleChange={cancelModal}
                url={url}
                isOpen={isOpen}
                link={link}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default MovieServers;
