import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import LoadingGrid from "../Loading/LoadingGrid";
import PosterGrid from "../PosterGrid/PosterGrid";

import { SignedIn, SignedOut } from "../UserState/UserState";
import Seo from "../Seo";
import "./Homepage.scss";
import Announcement from "../Ads/Announcement";

function Homepage({
  documentary,
  movies,
  network,
  thriller,
  kids,
  series,
  scifi,
  horror,
  loadingMovies,
  loadingScifi,
  loadingKids,
  loadingThriller,
  loadingHorror,
  loadingShows,
  loadingDocumentaries,
}) {
  return (
    <>
      <Seo
        title="FlixWatch - Watch Free Movies, TV Shows & Anime Online | HD Streaming"
        keywords="Watch free movies online, Stream TV shows, Watch anime online, Free movie streaming, Download movies HD, Latest movies 2024, Best streaming site, Ad-free movie watching, Hollywood movies free, Bollywood movies streaming, Japanese anime HD, K-dramas free, Best anime site, Free full movies, Watch cartoons online, Netflix alternatives free, Watch action movies, Top movie streaming website, 4K movies free, Watch latest TV shows, Disney+ free movies, Amazon Prime free streaming, Watch documentaries online, Best free sites to watch movies, Watch dubbed anime, Stream subbed anime, Watch new movies 2024, Watch horror movies free, Family movies free streaming, Watch sci-fi movies, Online movie site no ads, Best websites to watch movies for free, Download movies legally, Where to stream free movies, Free movie downloads, Best TV streaming services, Watch TV shows free without signing up, Best site to watch anime, Free anime downloads, Best site for TV shows, Watch drama series free, Watch full-length movies free, Watch top-rated movies online"
        descriptions="FlixWatch.site lets you stream the latest movies, TV shows, and anime for free in multiple languages. Watch in 1080p, 720p, and 480p quality without ads. Enjoy high-quality streaming of Hollywood, Bollywood, K-Dramas, Anime, and more. The best Netflix alternative for free movie and TV show streaming!"
      />

      <div id="homepage" className="container">
        <Announcement />
        <div className="home">Home</div>
        <section>
          <div className="main-blogs">
            <SignedIn>
              {() => (
                <>
                  <div className="main-blog anim" style={{ "--delay": ".1s" }}>
                    <div className="main-blog__title">
                      You are now <br />
                      FlixWatch + User.
                    </div>
                    <div className="main-blog__author">
                      <div className="author-detail">
                        <div className="author-name">
                          Enjoy the extra features.
                        </div>
                        <div className="author-info">
                          No Advert<span className="seperate"></span>Own Library
                          <span className="seperate"></span>1080p, CC!
                        </div>
                      </div>
                    </div>
                    <div className="main-blog__time">
                      <Link className="buttongg" to="/movies/year/2021">
                        New Movies!
                      </Link>
                    </div>
                  </div>
                  <div className="main-blog anim" style={{ "--delay": ".2s" }}>
                    <div className="main-blog__title">
                      If you like us share our site to your friends.
                    </div>
                    <div className="main-blog__author">
                      <div className="author-detail">
                        <div className="author-name">
                          Join to our official groups
                        </div>
                        <div className="author-info">
                          Invite Code <span className="seperate"></span>{" "}
                          Freebies<span className="seperate"></span> Rewards
                        </div>
                      </div>
                    </div>
                    <div className="main-blog__time">
                      <Link className="buttongg" to="/discord">
                        Let me In!
                      </Link>
                    </div>
                  </div>
                </>
              )}
            </SignedIn>
            <SignedOut>
              <div className="main-blog anim" style={{ "--delay": ".2s" }}>
                <div className="main-blog__title">
                  Discover the movies and TV shows and stream all free and
                  unlimited.
                </div>
                <div className="main-blog__author">
                  <div className="author-detail">
                    <div class="main-blog__author">
                      <div class="author-img__wrapper">
                        <svg
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          class="feather feather-check"
                        >
                          <path d="M20 6L9 17l-5-5"></path>
                        </svg>
                        <img
                          class="author-img"
                          src="https://i.imgur.com/pR1jZly.png"
                        />
                      </div>
                      <div class="auth-detail">
                        <div class="author-name">Yes, It's all free</div>
                        <div class="author-info">
                          HD Quality<span class="seperate"></span>CC
                          <span class="seperate"></span>Watchlist
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="main-blog__time">
                  <Link className="buttongg" to="/movies/year/2022">
                    New Movies!
                  </Link>
                </div>
              </div>
              <div className="main-blog anim" style={{ "--delay": ".1s" }}>
                <div className="main-blog__title">
                  If you like us share our site to your friends.
                </div>
                <div className="main-blog__author">
                  <div className="author-detail">
                    <div className="author-name">
                      Join to our official telegram and discord groups{" "}
                    </div>
                    <div className="author-info">
                      Invite Code <span className="seperate"></span> Freebies
                      <span className="seperate"></span> Rewards
                    </div>
                  </div>
                </div>
                <div className="main-blog__time1">
                  <Link
                    className="buttongg"
                    to="https://t.me/+vekZX4KtMPtiYmRl"
                  >
                    Telegram
                  </Link>
                </div>
                <div className="main-blog__time">
                  <Link className="buttongg" to="/discord">
                    Discord
                  </Link>
                </div>
              </div>
            </SignedOut>
          </div>
          <h2>Now playing movies</h2>
          {loadingMovies && <LoadingGrid grid="true" />}
          {!loadingMovies && (
            <PosterGrid movies={movies} view="grid" type="movie" />
          )}
        </section>

        <section>
          <h2>Top Thriller</h2>
          {loadingThriller && <LoadingGrid grid="true" />}
          {!loadingThriller && (
            <PosterGrid movies={thriller} view="grid" type="movie" />
          )}
        </section>
        <section>
          <h2>Top Sci-Fi</h2>
          {loadingScifi && <LoadingGrid grid="true" />}
          {!loadingScifi && (
            <PosterGrid movies={scifi} view="grid" type="movie" />
          )}
        </section>
        <section>
          <h2>Top Anime</h2>
          {loadingKids && <LoadingGrid grid="true" />}
          {!loadingKids && (
            <PosterGrid movies={kids} view="grid" type="movie" />
          )}
        </section>
        <section>
          <h2>Top Horror</h2>
          {loadingHorror && <LoadingGrid grid="true" />}
          {!loadingHorror && (
            <PosterGrid movies={horror} view="grid" type="movie" />
          )}
        </section>
        <section>
          <div className="main-blogs">
            <div className="main-blog1">
              <div className="main-blog__title">
                Keep updated what's happening!
              </div>
              <div className="main-blog__author tips">
                <div className="main-blog__time">
                  <a
                    className="buttongg"
                    href="https://discord.gg/yrMEckPEp3"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Discord
                  </a>
                </div>
                <div className="author-detail">
                  <div className="author-name">All the info are here</div>
                  <div className="author-info">
                    Announcement<span className="seperate"></span>Fix & Issues
                    <span className="seperate"></span>And More
                  </div>
                </div>
              </div>
            </div>
          </div>
          <h2>Now airing TV shows</h2>
          {loadingShows && <LoadingGrid grid="true" />}
          {!loadingShows && (
            <PosterGrid movies={series} view="grid" type="tv" />
          )}
        </section>
        <section>
          <h2>Top Anime Shows</h2>{" "}
          {/* Previously, this section displayed documentaries shows. Now, it displays anime shows instead. */}
          {loadingDocumentaries && <LoadingGrid grid="true" />}
          {!loadingDocumentaries && (
            <PosterGrid movies={documentary} view="grid" type="tv" />
          )}
        </section>
      </div>
    </>
  );
}

Homepage.propTypes = {
  movies: PropTypes.array.isRequired,
  series: PropTypes.array.isRequired,
  horror: PropTypes.array.isRequired,
  loadingHorror: PropTypes.bool.isRequired,
  loadingMovies: PropTypes.bool.isRequired,
  loadingShows: PropTypes.bool.isRequired,
};

export default Homepage;
