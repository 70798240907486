import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/auth";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyAHxRbec6AHZ_ZNdtP9wE69LrtRn9I7GSM",
    authDomain: "flixwatch-f0a8b.firebaseapp.com",
    projectId: "flixwatch-f0a8b",
    storageBucket: "flixwatch-f0a8b.firebasestorage.app",
    messagingSenderId: "744040720857",
    appId: "1:744040720857:web:921ef00586bb98624f11c8",
    measurementId: "G-SWPX8PELDT"
};

const firebaseApp = firebase.initializeApp(config);

// Initialize the Firestore database
const db = firebase.firestore();
const auth = firebase.auth();

export { db, auth };
export default firebaseApp;