import React, { Component } from "react";
import "./Settings.scss";
import axios from "axios";
import { SignedIn, SignedOut } from "../UserState/UserState";

class Settings extends Component {
  state = {
    openMovie: false,
    openSeries: false,
    openLanguage: false,
    languagedata: "",
    movie: "",
    series: "",
    languagename: "",
    language: [],
  };

  serverMapping = {
    movie: [
      "Vidlink",
      "Vidjoy",
      "EmbedSu",
      "Hindi Server",
      "Vidbinge",
      "111Movie Server",
      "Smashy Stream",
      "Portugues Subtitle",
      "French Server",
      "Vietnam Server",
      "Vidsrc",
    ],
    series: [
      "Vidlink",
      "Vidjoy",
      "EmbedSu",
      "Hindi Server",
      "Vidbinge",
      "111Movie Server",
      "Smashy Stream",
      "Portugues Subtitle",
      "French Server",
      "Vietnam Server",
      "Vidsrc",
    ],
  };

  componentDidMount() {
    this.setState({
      languagename: localStorage.getItem("language_eng"),
      movie: this.serverMapping.movie[localStorage.getItem("server_movie") - 1],
      series:
        this.serverMapping.series[localStorage.getItem("server_series") - 1],
    });

    const topLanguages = [
      "en",
      "zh",
      "hi",
      "es",
      "fr",
      "ar",
      "bn",
      "ru",
      "pt",
      "ja",
    ];

    axios
      .get(
        `https://api.themoviedb.org/3/configuration/languages?api_key=453752deba3272cd109112cd41127fd8`
      )
      .then((response) => {
        const languages = response.data
          .filter((lang) => topLanguages.includes(lang.iso_639_1))
          .map((lang) => ({
            iso_639_1: lang.iso_639_1,
            english_name: lang.english_name,
            name: lang.name,
          }));

        const englishExists = languages.some((lang) => lang.iso_639_1 === "en");

        this.setState({
          language: englishExists
            ? languages
            : [
                ...languages,
                { iso_639_1: "en", english_name: "English", name: "English" },
              ],
        });
      })
      .catch((err) => console.log(err));
  }

  // Open a specific menu (movie, series, or language)
  openMenu = (type) => {
    this.setState({ [type]: true });
  };

  // Close a specific menu (movie, series, or language)
  closeMenu = (type) => {
    this.setState({ [type]: false });
  };

  // Choose a server for movies or series and store the selection in localStorage
  chooseServer = (type, id) => {
    const name = this.serverMapping[type][id - 1]; // Get the server name based on the ID
    this.setState({ [type]: name }); // Update the state with the selected server
    localStorage.setItem(`server_${type}`, id); // Save the selection in localStorage
    this.closeMenu(`open${type.charAt(0).toUpperCase() + type.slice(1)}`); // Close the corresponding menu
  };

  // Handle changes in language selection
  handleLanguageChange = (e, iso_639_1) => {
    // Find the selected language object based on iso_639_1
    const selectedLanguage = this.state.language.find(
      (lang) => lang.iso_639_1 === iso_639_1
    );

    // If selectedLanguage is found, set the language and close the menu
    if (selectedLanguage) {
      localStorage.setItem("language_eng", selectedLanguage.english_name);
      localStorage.setItem("language", iso_639_1);
      this.setState({
        languagename: selectedLanguage.english_name, // Update the displayed language
        openLanguage: false, // Close the language menu
      });

      // Reload the page after the language change
      window.location.reload(false);
    }
  };

  render() {
    return (
      <div className="container settings">
        {/* Language Selection Section */}
        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">
                Change the default language source. (Need to reload to take
                effect)
              </div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div
                  className="zpicwb-11 jBNrZF"
                  onClick={() => this.openMenu("openLanguage")}
                >
                  <div className="zpicwb-3 bjxAPK">
                    {this.state.languagename || "Select Language"}
                  </div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg
                    onClick={() => this.closeMenu("openLanguage")}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN"
                    main="#9B9D9F"
                  >
                    <path
                      d="M17 9.5l-5 5-5-5"
                      stroke="#9B9D9F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <ul
                    className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ"
                    style={{
                      display: this.state.openLanguage ? "block" : "none",
                    }}
                  >
                    {this.state.language.map((movie, i) => (
                      <li
                        className="sc-12gjfzm-0 PMXqu"
                        onClick={(e) =>
                          this.handleLanguageChange(e, movie.iso_639_1)
                        }
                        key={i}
                      >
                        {movie.english_name}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Movie Source Section */}
        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">
                Change the default Movie Source.
              </div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div
                  className="zpicwb-11 jBNrZF"
                  onClick={() => this.openMenu("openMovie")}
                >
                  <div className="zpicwb-3 bjxAPK">
                    {this.state.movie || "Select Server"}
                  </div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg
                    onClick={() => this.closeMenu("openMovie")}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN"
                    main="#9B9D9F"
                  >
                    <path
                      d="M17 9.5l-5 5-5-5"
                      stroke="#9B9D9F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <ul
                    className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ"
                    style={{ display: this.state.openMovie ? "block" : "none" }}
                  >
                    {this.serverMapping.movie.map((server, i) => (
                      <li
                        className="sc-12gjfzm-0 PMXqu"
                        onClick={() => this.chooseServer("movie", i + 1)}
                        key={i}
                      >
                        {server}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* TV Show Source Section */}
        <div className="sc-1o36vqg-7 brWjXb">
          <header className="sc-1o36vqg-4 kYxrQw">
            <div className="sc-1o36vqg-3 iVeijx">
              <div className="sc-1o36vqg-0 hfwCMq">
                Change the default TV-shows Source.
              </div>
            </div>
          </header>
          <hr className="sc-120wsjt-0 sc-1o36vqg-2 keqMWH" />
          <div className="sc-1o36vqg-6 hwytcN">
            <div className="zpicwb-12 erjEHL">
              <div className="zpicwb-10 gmjUwa">
                <div
                  className="zpicwb-11 jBNrZF"
                  onClick={() => this.openMenu("openSeries")}
                >
                  <div className="zpicwb-3 bjxAPK">
                    {this.state.series || "Select Server"}
                  </div>
                </div>
                <div className="zpicwb-9 cHJOkB">
                  <svg
                    onClick={() => this.closeMenu("openSeries")}
                    width="20"
                    height="20"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    className="m9d8o3-0 fxbeUc zpicwb-8 eKqRtN"
                    main="#9B9D9F"
                  >
                    <path
                      d="M17 9.5l-5 5-5-5"
                      stroke="#9B9D9F"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    ></path>
                  </svg>
                  <ul
                    className="sc-12gjfzm-1 hqidmZ zpicwb-0 jBOakZ"
                    style={{
                      display: this.state.openSeries ? "block" : "none",
                    }}
                  >
                    {this.serverMapping.series.map((server, i) => (
                      <li
                        className="sc-12gjfzm-0 PMXqu"
                        onClick={() => this.chooseServer("series", i + 1)}
                        key={i}
                      >
                        {server}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Settings;
